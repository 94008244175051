<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo brand-logo-unlogin">
        <!-- <vuexy-logo /> -->
        <img
          src="@/assets/images/logo/transfersmile_big_logo.png"
          alt=""
        >
        <h2 class="brand-text text-primary ml-1 mt-2">
          Merchant Center
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-2"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            <!-- Adventure starts here 🚀 -->
            Register 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Please register your personal information!
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="registerMerchant"
            >
              <b-form-group
                label="Contact Name"
                label-for="register-contact-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Contact Name"
                  rules="required|validName"
                >
                  <b-form-input
                    id="register-contact-name"
                    v-model="contactName"
                    name="register-contact-name"
                    placeholder="Contact Name"
                    autocomplete="off"
                    :formatter="val => sliceStr(val, 75)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Company Full Name"
                label-for="company-full-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company Full Name"
                  rules="required"
                >
                  <b-form-input
                    id="company-full-name"
                    v-model="companyFullName"
                    name="company-full-name"
                    placeholder="Company Full Name"
                    autocomplete="off"
                    :formatter="val => sliceStr(val, 100)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Company Email"
                label-for="company-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company Email"
                  vid="company_email"
                  rules="required|email"
                >
                  <b-form-input
                    id="company-email"
                    v-model="companyEmail"
                    name="company-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Company Email"
                    autocomplete="off"
                    :formatter="val => sliceStr(val, 75)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Country"
                label-for="Country"
              >
                <validation-provider
                  ref="registerFormCountry"
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <v-select
                    v-model="country"
                    :clearable="false"
                    :value="country"
                    :options="countryList"
                    class="b-v-select"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Select One"
                    @close="() => $refs.registerFormCountry.validate()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <telephone-number
                ref="telephoneNumber"
                :area-code.sync="areaCode"
                :phone-number.sync="phoneNumber"
                :verification-code.sync="verificationCode"
              />
              <password-component
                ref="passwordComponent"
                :password.sync="password"
                :confirm-password.sync="confirmPassword"
              />
              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Click here to login</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import {
  defineComponent, ref, computed, getCurrentInstance, watch,
} from '@vue/composition-api'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'
import { required, email, confirmed } from '@validations'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  aseEncrypt, showToast, sliceStr,
} from '@/libs/utils'
import store from '@/store'
import storage from '@/libs/storage'
import telephoneNumber from './components/PhoneNumber.vue'
import passwordComponent from './components/PasswordComponent.vue'

const registerV2 = require('@/assets/images/pages/register-v2.svg')
// const baseUrlBean = require('@/libs/baseUrl')

// 手机号验证：小于11位且不等于0
extend('required', {
  ...required,
  message: 'Please enter your {_field_}.',
})

extend('validName', {
  validate(value) {
    const reg = /(?=.*[!@#$%^&*()])/
    return !reg.test(value)
  },
  message: 'The {_field_} is not valid',
})

export default defineComponent({
  async beforeCreate() {
    // 获取公共数据
    if (store.state.wallet.countryList.length === 0) { store.dispatch('wallet/getCommon') }
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const contactName = ref('')
    const companyFullName = ref('')
    const companyEmail = ref('')
    const country = ref(null)
    // 手机号
    const areaCode = ref('+55')
    const phoneNumber = ref('')
    const verificationCode = ref('')
    // 密码
    const password = ref('')
    const confirmPassword = ref('')
    const countryList = computed(() => store.state.wallet.countryList)
    const imgUrl = computed(() => registerV2)
    const getParams = () => {
      const params = {
        basic_info: {
          contact_name: contactName.value,
          company_full_name: companyFullName.value,
          company_email: companyEmail.value,
          phone_number: phoneNumber.value,
          area_code: areaCode.value.substring(1),
          country_code: country.value.value,
        },
        password: aseEncrypt(password.value),
        confirm_password: aseEncrypt(confirmPassword.value),
        verification_code: verificationCode.value,
      }
      return params
    }
    const registerMerchant = () => {
      proxy.$refs.registerForm.validate().then(async success => {
        if (success) {
          const params = getParams()
          const res = await proxy.$api.register(params)
          const { code, message } = res.data
          if (code === 200) {
            showToast({ proxy, title: 'success', message: 'Success' })
            storage.clearAll()
            proxy.$router.push({ name: 'auth-login' })
          } else {
            showToast({ proxy, title: 'danger', message })
          }
        }
      })
    }

    return {
      contactName,
      companyFullName,
      companyEmail,
      country,
      areaCode,
      phoneNumber,
      verificationCode,
      password,
      confirmPassword,
      countryList,
      imgUrl,

      required,
      email,
      confirmed,

      registerMerchant,
      sliceStr,
    }
  },
  provide() {
    return {
      scene: '3',
    }
  },
  components: {
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    vSelect,
    telephoneNumber,
    passwordComponent,

  },
})
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.brand-logo-unlogin img {
  width: auto;
  height: 70px;
}
.auth-wrapper {
  .input-group {
    flex-wrap: nowrap !important;
  }
  .input-group-prepend {
    height: 38px;
  }
  .b-dropdown-country {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
  .b-v-select {
    img {
      width: 30px;
      height: auto;
    }
  }
}
</style>
