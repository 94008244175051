var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo brand-logo-unlogin"},[_c('img',{attrs:{"src":require("@/assets/images/logo/transfersmile_big_logo.png"),"alt":""}}),_c('h2',{staticClass:"brand-text text-primary ml-1 mt-2"},[_vm._v(" Merchant Center ")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-2",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Register 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please register your personal information! ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.registerMerchant.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Contact Name","label-for":"register-contact-name"}},[_c('validation-provider',{attrs:{"name":"Contact Name","rules":"required|validName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-contact-name","name":"register-contact-name","placeholder":"Contact Name","autocomplete":"off","formatter":function (val) { return _vm.sliceStr(val, 75); }},model:{value:(_vm.contactName),callback:function ($$v) {_vm.contactName=$$v},expression:"contactName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Company Full Name","label-for":"company-full-name"}},[_c('validation-provider',{attrs:{"name":"Company Full Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-full-name","name":"company-full-name","placeholder":"Company Full Name","autocomplete":"off","formatter":function (val) { return _vm.sliceStr(val, 100); }},model:{value:(_vm.companyFullName),callback:function ($$v) {_vm.companyFullName=$$v},expression:"companyFullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Company Email","label-for":"company-email"}},[_c('validation-provider',{attrs:{"name":"Company Email","vid":"company_email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-email","name":"company-email","state":errors.length > 0 ? false:null,"placeholder":"Company Email","autocomplete":"off","formatter":function (val) { return _vm.sliceStr(val, 75); }},model:{value:(_vm.companyEmail),callback:function ($$v) {_vm.companyEmail=$$v},expression:"companyEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Country","label-for":"Country"}},[_c('validation-provider',{ref:"registerFormCountry",attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"b-v-select",attrs:{"clearable":false,"value":_vm.country,"options":_vm.countryList,"state":errors.length > 0 ? false:null,"placeholder":"Select One"},on:{"close":function () { return _vm.$refs.registerFormCountry.validate(); }},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('telephone-number',{ref:"telephoneNumber",attrs:{"area-code":_vm.areaCode,"phone-number":_vm.phoneNumber,"verification-code":_vm.verificationCode},on:{"update:areaCode":function($event){_vm.areaCode=$event},"update:area-code":function($event){_vm.areaCode=$event},"update:phoneNumber":function($event){_vm.phoneNumber=$event},"update:phone-number":function($event){_vm.phoneNumber=$event},"update:verificationCode":function($event){_vm.verificationCode=$event},"update:verification-code":function($event){_vm.verificationCode=$event}}}),_c('password-component',{ref:"passwordComponent",attrs:{"password":_vm.password,"confirm-password":_vm.confirmPassword},on:{"update:password":function($event){_vm.password=$event},"update:confirmPassword":function($event){_vm.confirmPassword=$event},"update:confirm-password":function($event){_vm.confirmPassword=$event}}}),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" Sign up ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" Click here to login")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }